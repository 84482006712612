import './style';
import Home from './routes/home';
import Router from 'preact-router';
import { h, render } from 'preact';
import SafetyRedirect from './routes/Redirect';

const Main = () => (
    <Router>
        <Home path="/" />
        <SafetyRedirect path="/invitation" />
        <SafetyRedirect path="/download" />
        <SafetyRedirect path="/o/:id" />
        <SafetyRedirect path="/o/:id/:name" />
        <SafetyRedirect path="/email-verified" />
    </Router>
);

render(<Main />, document.body);